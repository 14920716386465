import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as xlsx from 'xlsx-js-style';
import './App.css'
import Modal from './Modal';

export interface FileData {
  name: string,
  sheets: Record<string, Array<Array<string>>>,
  targetSheets: Array<string>,
  comparisonFields: Record<string, string>, // sheetName: fieldName
  workbook: xlsx.WorkBook
}

function indexes2Cell(row: number, column: number) {
  row++; column++; //! row && column need to be 1-indexed
  
  let cell = '';
  while (column > 0) {
    const remainder = (column - 1) % 26;
    cell = String.fromCharCode(65 + remainder) + cell;
    column = Math.floor((column - 1) / 26);
  }
  return cell + row;
}

export function compareSheets(baseDataArr: Array<Array<string>>, comparisonDataArr: Array<Array<string>>, baseComparisonField: string, comparisonField: string) {
  const cleanBaseDataArr: Array<Array<string>> = [[]];
  baseDataArr[0].forEach((field: string) => {
    if (field === comparisonField && baseComparisonField !== comparisonField) return;
    cleanBaseDataArr[0].push(field === baseComparisonField ? comparisonField : field);
  });
  baseDataArr.slice(1).forEach((rowArr: string[]) => {
    let row: string[] = [];
    rowArr.forEach((entry: string, index: number) => {
      if (baseDataArr[0][index] === comparisonField && baseComparisonField !== comparisonField) return;
      row.push(entry);
    });
    cleanBaseDataArr.push(row);
  });

  const baseData: Array<Record<string, string>> = []; 
  cleanBaseDataArr.slice(1).forEach((rowArr: string[]) => {
    let rowMap: Record<string, string> = {};
    rowArr.forEach((entry: string, index: number) => {
      rowMap[cleanBaseDataArr[0][index]] = entry;
    });
    baseData.push(rowMap);
  });
  const baseKeys = baseData.map((rowObj: Record<string, string>) => rowObj[comparisonField]);

  const cleanComparisonDataArr: Array<Array<string>> = [[]];
  comparisonDataArr[0].forEach((field: string) => {
    if (field === baseComparisonField && baseComparisonField !== comparisonField) return;
    cleanComparisonDataArr[0].push(field);
  });
  comparisonDataArr.slice(1).forEach((rowArr: string[]) => {
    let row: string[] = [];
    rowArr.forEach((entry: string, index: number) => {
      if (comparisonDataArr[0][index] === baseComparisonField && baseComparisonField !== comparisonField) return;
      row.push(entry);
    });
    cleanComparisonDataArr.push(row);
  });

  const comparisonData: Array<Record<string, string>> = []; 
  cleanComparisonDataArr.slice(1).forEach((rowArr: string[]) => {
    let rowMap: Record<string, string> = {};
    rowArr.forEach((entry: string, index: number) => {
      rowMap[cleanComparisonDataArr[0][index]] = entry;
    });
    comparisonData.push(rowMap);
  });
  const compareKeys = comparisonData.map((rowObj: Record<string, string>) => rowObj[comparisonField]);

  type ResultMetaData = 'new' | 'deleted' | 'modified' | 'none';
  interface ResultCell {
    value: string,
    metaData: ResultMetaData
  }

  const resultData: Array<Record<string, ResultCell>> = [];
  for (const rowData of comparisonData) {
    const resultRow: Record<string, ResultCell> = {};
    if (!baseKeys.includes(rowData[comparisonField])) {
      for (const [field, value] of Object.entries(rowData)) {
        resultRow[field] = {
          value: value,
          metaData: 'new'
        };
      }
      for (const field of cleanBaseDataArr[0]) {
        if (Object.keys(rowData).includes(field)) continue;
        resultRow[field] = {
          value: '',
          metaData: 'deleted'
        };
      }
    } else {
      const baseRow = baseData.find((rowObj: Record<string, string>) => rowObj[comparisonField] === rowData[comparisonField])!;
      for (const [field, value] of Object.entries(rowData)) {
        if (baseRow[field] === undefined) {
          resultRow[field] = {
            value: value,
            metaData: 'new'
          };
        } else if (value !== baseRow[field]) {
          resultRow[field] = {
            value: `${baseRow[field]} -> ${value}`,
            metaData: 'modified'
          };
        } else {
          resultRow[field] = {
            value: value,
            metaData: 'none'
          };
        }
      }
      for (const field in baseRow) {
        if (Object.keys(rowData).includes(field)) continue;
        resultRow[field] = {
          value: baseRow[field],
          metaData: 'deleted'
        };
      }
    }
    resultData.push(resultRow);
  }

  for (const rowData of baseData) {
    if (compareKeys.includes(rowData[comparisonField])) continue;
    const resultRow: Record<string, ResultCell> = {};
    for (const [field, value] of Object.entries(rowData)) {
      resultRow[field] = {
        value: value,
        metaData: 'deleted'
      };
    }
    for (const field of cleanComparisonDataArr[0]) {
      if (Object.keys(rowData).includes(field)) continue;
      resultRow[field] = {
        value: '',
        metaData: 'deleted'
      };
    }
    resultData.push(resultRow);
  }

  return resultData
}

function Compare() {
  const [fileData, setFileData] = useState<FileData[]>([]);
  const [baseFileName, setBaseFileName] = useState<string>('');
  const [sameField, setSameField] = useState(true);
  const [compareSingle, setCompareSingle] = useState<Record<string, boolean>>({});

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function activateModal(message: string) {
    setErrorMessage(message);
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 2500);
  }

  function getFileData(fileName: string): FileData | undefined {
    return fileData.find((file: FileData) => file.name === fileName);
  }

  function getFileIdx(fileName: string): number {
    return fileData.findIndex((file: FileData) => file.name === fileName);
  }

  function readExcelFile(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = function (e) {
        let result = e.target!.result;
        let workbook = xlsx.read(result, { type: 'binary', dateNF: 'dd/mm/yyyy' });
        let sheets: Record<string, Array<Array<string>>> = {};
        const comparisonFields: Record<string, string> = {};
        for (const sheetName of workbook.SheetNames) {
          let sheet = workbook.Sheets[sheetName];
          let dataArr = xlsx.utils.sheet_to_json(sheet, { header: 1, defval: '', dateNF: 'dd/mm/yyyy' }) as Array<Array<string>>;
          comparisonFields[sheetName] = dataArr[0].some(field => field === 'Tag') ? 'Tag' : dataArr[0][0].toString();
          for (let i = 0; i < dataArr.length; i++) {
            const row = dataArr[i] as any[];
            for (let j = 0; j < row.length; j++) {
              const cell = indexes2Cell(i, j);
              const cellData = workbook.Sheets[sheetName][cell];
              if (cellData?.hasOwnProperty('w')) {
                row[j] = cellData.w.toString();
                continue;
              }
              try {
                row[j] = row[j].toString();
              } catch (error) {
                row[j] = '';
              }
            }
          }
          sheets[sheetName] = dataArr;
        }

        resolve({
          sheets: sheets,
          targetSheets: [workbook.SheetNames[0]],
          comparisonFields: comparisonFields,
          workbook: workbook
        } as FileData)
      };

      reader.onerror = function (err) {
        reject(err);
      };

      reader.readAsBinaryString(file);
    });
  }

  function onDrop(acceptedFiles: any) {    
    let tempFileData = [...fileData];
    let filteredFiles: FileData[] = [];
    for (const newFileData of acceptedFiles) {
      if (tempFileData.some((f: any) => f.name.split('.')[0] === newFileData.name.split('.')[0])) continue;
      filteredFiles.push(newFileData);
      tempFileData.push(newFileData);
    }
    if (filteredFiles.length !== acceptedFiles.length) {
      activateModal('File names must be unique!');
    }

    let newFilesPromises: Promise<FileData>[] = filteredFiles.map(async (file: any) => {
      let fileData = await readExcelFile(file) as any;
      fileData.name = file.name;
      return fileData;
    });

    Promise.all(newFilesPromises)
      .then(newFileData => {
        setFileData(fileData => [...fileData, ...newFileData]);
        newFileData.forEach((data: any) => {
          setCompareSingle({...compareSingle, [data.name]: true});
        });
      })
      .catch(error => {
          console.error('Error reading Excel files:', error);
      });
  };
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'], // ! check can be opened too
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    }
  })

  function handleRemove(e: React.MouseEvent<HTMLButtonElement>, index: number) {
    e.stopPropagation();
    if (baseFileName === fileData[index].name) setBaseFileName('');
    const updatedFiles = [...fileData];
    updatedFiles.splice(index, 1);
    setFileData(updatedFiles);
  }

  function clearAll(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setBaseFileName('');
    setFileData([]);
  }

  const handleSelectBaseFile = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBaseFileName(e.target.value);
  };

  function setTargetSheet(fileName: string, sheetName: string) {
    const updatedFileData = [...fileData];
    const targetFileData = getFileData(fileName);
    if (targetFileData === undefined) return;
    targetFileData.targetSheets = [sheetName];
    updatedFileData[getFileIdx(fileName)] = targetFileData;
    setFileData(updatedFileData);
  }

  function handleSelectBaseSheet(e: React.ChangeEvent<HTMLSelectElement>) {
    setTargetSheet(baseFileName, e.target.value);
  }

  function handleCompareSingleChange(e: React.ChangeEvent<HTMLInputElement>, fileName: string) {
    setCompareSingle({...compareSingle, [fileName]: e.target.checked});
    if (!e.target.checked) return;
    const updatedFileData = [...fileData];
    const targetFileData = getFileData(fileName);
    if (targetFileData === undefined) return;
    targetFileData.targetSheets = [targetFileData.targetSheets[0]];
    updatedFileData[getFileIdx(fileName)] = targetFileData;
    setFileData(updatedFileData);
  }

  const handleSelectSheet = (e: React.ChangeEvent<HTMLSelectElement>, fileName: string) => {
    setTargetSheet(fileName, e.target.value);
    checkFields();
  };

  function handleSelectSheets(e: React.ChangeEvent<HTMLInputElement>, fileName: string) {
    const updatedFileData = [...fileData];
    const targetFileData = getFileData(fileName);
    if (targetFileData === undefined) return;
    if (e.target.checked && !targetFileData.targetSheets.includes(e.target.value)) {
      targetFileData.targetSheets.push(e.target.value);
    } else if (targetFileData.targetSheets.length > 1) {
      targetFileData.targetSheets = targetFileData.targetSheets.filter((sheetName: string) => sheetName !== e.target.value);
    }
    updatedFileData[getFileIdx(fileName)] = targetFileData;
    setFileData(updatedFileData);
    checkFields();
  }

  const handleSelectField = (e: React.ChangeEvent<HTMLSelectElement>, fileName: string) => {
    const updatedFileData = [...fileData];
    const targetFileData = getFileData(fileName);
    if (targetFileData === undefined) return;
    targetFileData.comparisonFields[targetFileData.targetSheets[0]] = e.target.value;
    updatedFileData[getFileIdx(fileName)] = targetFileData;
    setFileData(updatedFileData);
  };

  const handleSelectFields = (e: React.ChangeEvent<HTMLSelectElement>, fileName: string, sheetName: string) => {
    const updatedFileData = [...fileData];
    const targetFileData = getFileData(fileName);
    if (targetFileData === undefined) return;
    targetFileData.comparisonFields[sheetName] = e.target.value;
    updatedFileData[getFileIdx(fileName)] = targetFileData;
    setFileData(updatedFileData);
  };

  function handleSameFieldChange() {
    setSameField(!sameField);
  }

  useEffect(() => {
    if (fileData.length === 0) {
      setBaseFileName('');
    } else if (baseFileName === '') {
      setBaseFileName(fileData[0].name);
    }
  }, [fileData, baseFileName]);

  function getCommonFields(): Array<string> {
    const baseFileData = getFileData(baseFileName);
    if (baseFileData === undefined) return [];
    
    const fields = baseFileData.sheets[baseFileData.targetSheets[0]][0];
    const toRemove: Array<string> = []
    for (const data of fileData) {
      if (data.name === baseFileName) continue;
      for (const sheetName of data.targetSheets) {
        for (const field of fields) {
          if (data.sheets[sheetName][0].includes(field)) continue;
          toRemove.push(field);
        }
      }
    }
    return fields.filter((field: string) => !toRemove.includes(field));
  }

  function checkFields() {
    if (!sameField) return;
    const commonFields = getCommonFields();
    const updatedFileData = [...fileData];
    for (const data of updatedFileData) {
      for (const sheetName of data.targetSheets) {
        if (commonFields.includes(data.comparisonFields[sheetName])) continue;
        data.comparisonFields[sheetName] = commonFields[0];
      }
    }
    setFileData(updatedFileData);
  }

  function compareSheetsWrapper(fileName: string, sheetName: string) {
    const baseFileData = getFileData(baseFileName);
    const comparisonFileData = getFileData(fileName);
    
    if (baseFileData === undefined || comparisonFileData === undefined) return;

    const baseDataArr = baseFileData.sheets[baseFileData.targetSheets[0]];
    const comparisonDataArr = comparisonFileData.sheets[sheetName];

    const baseComparisonField = baseFileData.comparisonFields[baseFileData.targetSheets[0]];
    const comparisonField = sameField ? baseComparisonField : comparisonFileData.comparisonFields[sheetName];    

    const resultData = compareSheets(baseDataArr, comparisonDataArr, baseComparisonField, comparisonField);

    const sheetDataArr: Array<Array<string>> = [[]];
    for (const field of comparisonDataArr[0]) {
      if (field === baseComparisonField && baseComparisonField !== comparisonField) continue;
      sheetDataArr[0].push(field);
    }
    for (const field of baseDataArr[0]) {
      if (comparisonDataArr[0].includes(field) || field === baseComparisonField || field === comparisonField) continue;
      sheetDataArr[0].push(field);
    }

    for (const rowData of resultData) {
      const row = [];
      for (const field of sheetDataArr[0]) {
        row.push(rowData[field].value);
      }
      sheetDataArr.push(row);
    }

    const worksheet = xlsx.utils.aoa_to_sheet(sheetDataArr, { dateNF: 'dd/mm/yyyy' });
    for (let j = 0; j < sheetDataArr[0].length; j++) {
      const cell = indexes2Cell(0, j);
      worksheet[cell].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: '1a1a1a' },
        },
        font: { color: { rgb: 'FFFFFF' } }
      }
    }

    const red = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FF0000' },
      },
    };
    const green = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: '00FF00' },
      },
    };
    const orange = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFA500' },
      },
    };

    for (let i = 0; i < resultData.length; i++) {
      for (const field of sheetDataArr[0]) {
        const cell = indexes2Cell(i + 1, sheetDataArr[0].indexOf(field));
        if (resultData[i][field].metaData === 'new') {
          worksheet[cell].s = green;
        } else if (resultData[i][field].metaData === 'deleted') {
          worksheet[cell].s = red;
        } else if (resultData[i][field].metaData === 'modified') {
          worksheet[cell].s = orange;
        }
      }
    }

    return worksheet;
  }

  function handleDownload() {
    if (baseFileName === '') return console.error("no base file");

    let resultFiles: any[] = [];
    fileData.forEach((data) => {
      if (data.name === baseFileName) return;

      let resultSheets: any[] = [];
      data.targetSheets.forEach((sheetName) => {
        resultSheets.push({
          name: sheetName,
          sheet: compareSheetsWrapper(data.name, sheetName)
        });
      });

      const workbook = xlsx.utils.book_new();
      for (const resultSheet of resultSheets) {
        xlsx.utils.book_append_sheet(workbook, resultSheet.sheet, resultSheet.name);
      }

      resultFiles.push({
        workbook: workbook, 
        name: `${data.name.split('.')[0]}_result.xlsx`
      });
    });

    resultFiles.forEach((resultFile) => {
      xlsx.writeFile(resultFile.workbook, resultFile.name, { bookType: 'xlsx', type: 'file' });
    })
  }

  return (
    <div style={{margin: 'auto 50px'}} >
      <Modal errorMessage={errorMessage} showModal={showModal} />
      <div {...getRootProps()} style={dropzoneStyles}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {fileData.map((data: any, i) => (
            <li key={`file_list${data.name}`} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0' }}>
              <span style={{ textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 'auto', maxWidth: '400px', marginRight: '20px'}} className='file-font'>{data.name}</span>
              <button onClick={(e) => handleRemove(e, i)} style={shadowButton}><i className="material-icons remove-button">close</i></button>
            </li>
          ))}
        </ul>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            fileData.length > 0 ? 
              <p>Drag 'n' drop additional files here, or click to select files</p> :
              <p>Drag 'n' drop files here, or click to select files</p>
        }
        {fileData.length >= 1 && <button onClick={(e) => clearAll(e)} disabled={fileData.length === 0} className='clear-button'>clear</button>}
      </div>

      <br />
      {fileData.length > 1 && 
        <div style={flexColumnStyles}>
          <div style={optionFlexStyles}>
            <label style={labelStyles}>Choose a base file:</label>
            <select
              value={baseFileName}
              onChange={handleSelectBaseFile}
              style={selectStyles}
            >
              {fileData.map((file, index) => (
                <option key={index} value={file.name}>
                  {file.name}
                </option>
              ))}
            </select>
            {(getFileData(baseFileName) !== undefined && Object.keys(getFileData(baseFileName)!.sheets).length > 1) &&
              <select
                value={getFileData(baseFileName)!.targetSheets[0]}
                onChange={handleSelectBaseSheet}
                style={selectStyles}
              >
                {Object.keys(getFileData(baseFileName)!.sheets).map((sheetName, index) => (
                  <option key={index} value={sheetName}>
                    {sheetName}
                  </option>
                ))}
              </select>
            }
          </div>

          {fileData.filter((data) => { return Object.keys(data.sheets).length > 1 && data.name !== baseFileName}).map((data, index) => {
            return (
              <div>
                <div className="divider"></div>
                <div style={optionFlexStyles}>
                  <label style={labelStyles}>Compare a single sheet in <span className='file-font'>{data.name}</span>?</label>
                  <input
                    type="checkbox"
                    checked={compareSingle[data.name]}
                    onChange={(e) => handleCompareSingleChange(e, data.name)}
                    style={checkboxStyles}
                  />
                </div>
                {compareSingle[data.name] ? 
                  <div key={index} style={optionFlexStyles}>
                    <label style={labelStyles}>Choose the target sheet for <span className='file-font'>{data.name}</span>:</label>
                    <select
                      value={data.targetSheets[0]}
                      onChange={(e) => handleSelectSheet(e, data.name)}
                      style={selectStyles}
                    >
                      {Object.keys(data.sheets).map((sheetName, index) => (
                        <option key={index} value={sheetName}>
                          {sheetName}
                        </option>
                      ))}
                    </select>
                  </div>
                  :
                  <div key={index} style={optionFlexStyles}>
                    <label style={labelStyles}>Select target sheets for <span className='file-font'>{data.name}</span>:</label>
                    {Object.keys(data.sheets).map((sheetName, index) => {
                      return (
                        <>
                          <label style={labelStyles}>{sheetName}</label>
                          <input
                            type="checkbox"
                            value={sheetName}
                            checked={data.targetSheets.includes(sheetName)}
                            onChange={(e) => handleSelectSheets(e, data.name)}
                            style={checkboxStyles}
                          />
                        </>
                      )
                    })}
                  </div>
                }
              </div>   
            )
          })}
          <div className="divider"></div>
          <div style={optionFlexStyles}>
            <label style={labelStyles}>Use same comparison field for all files?</label>
            <input
              type="checkbox"
              checked={sameField}
              onChange={handleSameFieldChange}
              style={checkboxStyles}
            />
          </div>
          
          {sameField ? 
            <div style={optionFlexStyles}>
              <label style={labelStyles}>Choose the comparison field:</label>
              <select
                value={getFileData(baseFileName) === undefined ? '' : getFileData(baseFileName)!.comparisonFields[getFileData(baseFileName)!.targetSheets[0]]}
                onChange={(e) => handleSelectField(e, baseFileName)}
                style={selectStyles}
              >
                {getCommonFields().map((field, index) => (
                  <option key={index} value={field}>
                    {field}
                  </option>
                ))}
              </select>
            </div>
            :
            <>
              {fileData.map((data, index1) => {
                return (
                  data.targetSheets.map((sheetName, index2) => {
                    if (data.name === baseFileName && sheetName !== data.targetSheets[0]) return null;
                    return (
                      <div key={`${index1}${index2}}`} style={optionFlexStyles}>
                        <label style={labelStyles}>Choose the comparison field for <span className='file-font'>{data.name}</span> / <span className='file-font'>{sheetName}</span>:</label>
                        <select
                          value={data.comparisonFields[sheetName]}
                          onChange={(e) => handleSelectFields(e, data.name, sheetName)}
                          style={selectStyles}
                        >
                          {data.sheets[sheetName][0].map((field, index3) => (
                            <option key={index3} value={field}>
                              {field}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                  })
                )
              })}
            </>
          } 
          <div className="divider"></div>
          <button className='compare-button' onClick={handleDownload}>compare</button>
        </div>
      }
    </div>
  )
}

export default Compare;

const dropzoneStyles: React.CSSProperties = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  maxWidth: '550px',
  minWidth: '200px',
  margin: '20px auto',
  backgroundColor: '#eee',
  position: 'relative',
};

const shadowButton: React.CSSProperties = {
  position: 'relative', 
  overflow: 'hidden', 
  border: 'none', 
  padding: 0,
  marginBottom: 0,
  placeItems: 'center',
}

const selectStyles: React.CSSProperties = {
  maxHeight: '100px',
  width: '100%',
  padding: '10px',
  margin: '10px 0',
  border: '1px solid #ccc',
  borderRadius: '4px',
  boxSizing: 'border-box',
  maxWidth: '350px',
};

const labelStyles: React.CSSProperties = {
  display: 'block',
  marginRight: '25px',
}

const optionFlexStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  height: '50px'
}

const checkboxStyles: React.CSSProperties = {
  transform: 'scale(1.5)',
  borderRadius: '5px',
  padding: '5px',
  cursor: 'pointer',
};

const flexColumnStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
  marginBottom: '30px',
}