import Compare from "./Compare";

function Welcome() {
  return (
    <>
      This site has moved <a href="https://webtools.downerutilitiesai.com/" target="_blank" >here</a>.
    </>
  );
}

export default Welcome;