import logo from './assets/logo.png';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const Navbar = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const userEmail = accounts[0] ? accounts[0].username : null;

  const handleLogin = () => {
    instance.loginRedirect();
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <nav style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', backgroundColor: '#333', color: '#fff' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '30px', marginRight: '1rem' }} />
        <span>Utilities Spreadsheet Comparison</span>
      </div>

      {isAuthenticated ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>{userEmail}</span>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <button onClick={handleLogin} >Login</button>
      )}
    </nav>
  );
};

export default Navbar;