import './App.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import Home from './Home';
import Welcome from './Welcome';
import Navbar from './Navbar';
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

const msalConfiguration: Configuration = {
  auth: {
      clientId: "a7005458-6122-4295-87e2-47d4cc4d4ac9", 
      authority: "https://login.microsoftonline.com/fa4bc6e2-c0ed-43ec-9d6d-145e7e36a1a5",
  }
};

const pca = new PublicClientApplication(msalConfiguration);

function App() {
  return (
    <MsalProvider instance={pca}>
      <Navbar />
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Welcome />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
