import './App.css';

interface Props {
  errorMessage: string;
  showModal: boolean;
}

function Modal({ errorMessage, showModal }: Props) {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div className={`custom-modal-container ${showModal ? 'visible' : ''}`}>
        <p style={{color: 'white', wordWrap: 'break-word'}}>{errorMessage}</p>
          <div className={`progress-bar ${showModal ? 'begin' : ''}`} id="progress"></div>
      </div>
    </div>
  )
}

export default Modal;